<template>
  <section class="order mt-6">
    <div class="container">
      <div class="row" v-html="$t('mainOrder.title')"></div>
      <div class="row mt-5 gx-5">
        <div class="col-sm-6">
          <div class="order__card order__card_single">
            <p>
              {{ $t('mainOrder.firstBlock') }}
            </p>

            <p />
          </div>
        </div>
        <div class="col-sm-6 mt-5 mt-sm-0">
          <div class="order__card order__card_double">
            <p>
              {{ $t('mainOrder.secondBlock') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.order {
  &__card {
    background-color: white;
    padding: 7.7rem 2rem 1.5rem 2rem;
    border-radius: 0.625rem;

    &_single,
    &_double {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    &_single {
      &::before {
        top: -1rem;
        left: -3rem;
        opacity: 0.3;
        background-image: url('../../assets/index/single-order.svg');
        background-repeat: no-repeat;
      }
    }

    &_double {
      &::before {
        top: -1rem;
        left: -2.5rem;
        background-image: url('../../assets/index/double-order.svg');
        background-repeat: no-repeat;
      }
    }
  }
}
</style>

<template>
  <section class="how-we-work mt-6">
    <div class="container">
      <div class="row">
        <div class="col" v-html="$t('mainHowWeWork.title')"></div>
        <div class="col-sm-5 d-flex justify-content-end align-items-end mb-2 mt-5">
          <Button
            :color="currentTab !== 'buyer' ? 'transparent' : 'primary'"
            :title="$t('mainHowWeWork.buyerButton')"
            @click="switchTab('buyer')"
          />
          <Button
            :color="currentTab !== 'seller' ? 'transparent' : 'primary'"
            :title="$t('mainHowWeWork.sellerButton')"
            @click="switchTab('seller')"
          />
        </div>
      </div>
      <transition-group tag="div" class="row mt-5" name="list">
        <template v-if="currentTab === 'buyer'">
          <div class="col-sm-3" key="b-1">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.firstBlockBuyer.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.firstBlockBuyer.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="b-2">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.secondBlockBuyer.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.secondBlockBuyer.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="b-3">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.thirdBlockBuyer.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.thirdBlockBuyer.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="b-4">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.fourthBlockBuyer.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.fourthBlockBuyer.description') }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="currentTab === 'seller'">
          <div class="col-sm-3" key="s-1">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.firstBlockSeller.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.firstBlockSeller.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="s-2">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.secondBlockSeller.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.secondBlockSeller.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="s-3">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.thirdBlockSeller.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.thirdBlockSeller.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-3" key="s-4">
            <div
              class="how-we-work__card p-4  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                :src="getUrl + $t('mainHowWeWork.fourthBlockSeller.icon.path')"
                class="img-fluid"
                alt="world"
              />
              <p class="text-center mt-4 mb-0">
                {{ $t('mainHowWeWork.fourthBlockSeller.description') }}
              </p>
            </div>
          </div>
        </template>
      </transition-group>
    </div>
  </section>
</template>

<script>
import enviroment from '@/mixins/enviroment';
import Button from '@/components/ui/Button';

export default {
  mixins: [enviroment],
  components: {
    Button
  },
  data() {
    return {
      currentTab: 'buyer'
    };
  },
  methods: {
    switchTab(val) {
      this.currentTab = val;
    }
  }
};
</script>

<style scoped lang="scss">
.how-we-work {
  &__card {
    height: 20rem;
    box-shadow: 0rem 3.25rem 3.375rem rgba(101, 83, 62, 0.0968);
    border-radius: 0.625rem;
  }
}

.list-enter-active {
  transition: all 1s ease;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(70px);
}
</style>

import axios from 'axios';

const axiosAPI = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Cockpit-Token': process.env.VUE_APP_API_KEY
  }
});

const apiRequest = (method, url, request) => {
  return axiosAPI({
    method,
    url,
    data: request
  })
    .then(res => {
      return Promise.resolve(res.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

const get = (url, request) => apiRequest('get', url, request);
const post = (url, request) => apiRequest('post', url, request);

const API = {
  get,
  post
};

export default API;

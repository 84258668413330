<template>
  <button
    type="button"
    @click="$emit('click')"
    :class="['btn', `btn-${color}`, { 'btn-bold': bold }]"
  >
    <span v-html="icon"></span>
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary'
    },
    bold: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  border-radius: 30px;
  padding: 15px 30px;
  font-size: 1.125rem;
  font-weight: 300;
  width: auto;

  &-bold {
    font-weight: 600;
  }
}
</style>

<template>
  <div class="modal d-flex align-items-center">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Modal'
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  background-color: rgba($color: #000000, $alpha: 0.5);

  &-dialog {
    width: auto;
    min-width: 25.875rem;
  }

  &-header {
    border: none;
    padding: 1rem 1rem 0 1rem;
  }

  &-title {
    font-family: 'Fira Sans', sans-serif;
  }
}
</style>

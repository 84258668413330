<template>
  <section class="about mt-5">
    <div class="container">
      <div class="row" v-html="$t('mainAbout.title')"></div>
      <div class="row">
        <div
          class="col-sm-7 mt-5 pe-5 d-flex flex-column justify-content-space-between"
          v-html="$t('mainAbout.description')"
        ></div>
        <div class="col-sm-5 mt-5">
          <img :src="getUrl + image" class="img-fluid" alt="..." />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-7 mt-5">
          <p class="about__paragraph_bold">
            {{ $t('mainAbout.accreditation') }}
          </p>
        </div>
      </div>
      <div class="row about__slider mt-3">
        <div class="col">
          <div class="about__swiper swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(slide, index) in $t('mainAbout.slider')"
                :key="`slide-${index}`"
              >
                <img :src="getUrl + slide.value.image.path" />
                <div class="about__slider-content">
                  <h2 class="about__slider-title">{{ slide.value.title }}</h2>
                  <p class="about__slider-paragraph mt-4">
                    {{ slide.value.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-navigation">
              <img class="swiper-next" src="../../assets/index/icons/arrow.svg" alt="next" />
              <img class="swiper-prev" src="../../assets/index/icons/arrow.svg" alt="prev" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import enviroment from '@/mixins/enviroment';
import Swiper, { Navigation, Pagination, Autoplay, EffectCube } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination, Autoplay, EffectCube]);

export default {
  mixins: [enviroment],
  props: {
    image: {
      type: String
    }
  },

  mounted() {
    new Swiper('.about__swiper', {
      direction: 'vertical',
      effect: 'cube',
      grabCursor: true,
      cubeEffect: {
        shadow: true,
        slideShadows: false,
        shadowOffset: 20,
        shadowScale: 0.94
      },
      autoplay: {
        delay: 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      }
    });
  }
};
</script>

<style lang="scss">
.about {
  &__paragraph {
    font-size: 1.375rem;

    &_bold {
      font-weight: 500;
      font-size: 1.75rem;
    }
  }

  &__link {
    &_ets {
      color: #3e85ef !important;
      text-decoration: none;
    }
  }

  &__slider {
    &-content {
      position: absolute;
      bottom: 5%;
      width: 70%;
      left: 7%;
      color: #fff;
      z-index: 2;
    }

    &-title {
      font-family: 'Fira Sans', sans-serif;
      color: #ffffff;
    }

    &-paragraph {
      display: block;
    }

    .swiper-container {
      width: 100%;
      height: 30.125rem;
    }

    .swiper-slide {
      &::after {
        content: '';
        position: absolute;
        background-color: #000000;
        opacity: 0.3;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }

      & img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        position: relative;
      }
    }

    .swiper-navigation {
      position: absolute;
      bottom: 10%;
      right: 7%;
      z-index: 5;
      width: auto;

      .swiper-next,
      .swiper-prev {
        opacity: 0.5;

        &:hover {
          opacity: 1;
          transition: 0.3s;
        }
      }

      .swiper-next {
        margin-right: 1.5rem;
      }

      .swiper-prev {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

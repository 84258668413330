<template>
  <section class="contacts mt-6" id="contacts">
    <div class="container">
      <div class="row">
        <h2>
          {{ $t('mainContacts.title') }}
        </h2>
      </div>
      <div class="row mt-5">
        <div class="col-sm-4">
          <h6 class="contacts__title">
            {{ $t('mainContacts.phone') }}
          </h6>
          <a class="contacts__link" :href="`tel:${$t('mainContacts.phoneValue')}`">
            {{ $t('mainContacts.phoneValue') }}
          </a>
        </div>
        <div class="col-sm-4">
          <h6 class="contacts__title">
            {{ $t('mainContacts.address') }}
          </h6>
          <a class="contacts__link">
            {{ $t('mainContacts.addressValue') }}
          </a>
        </div>
        <div class="col-sm-4">
          <h6 class="contacts__title">
            {{ $t('mainContacts.email') }}
          </h6>
          <a class="contacts__link" :href="`mailto:${$t('mainContacts.emailValue')}`">
            {{ $t('mainContacts.emailValue') }}
          </a>
        </div>
      </div>
      <div class="row mt-5" v-if="loadedMap">
        <yandex-map v-bind="map">
          <ymap-marker v-bind="marker" />
        </yandex-map>
      </div>
    </div>
  </section>
</template>

<script>
import API from '@/api';

import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  components: { yandexMap, ymapMarker },
  data() {
    return {
      map: {
        settings: {
          apiKey: '',
          lang: 'ru_RU',
          coordorder: 'latlong',
          version: '2.1'
        },
        coords: null,
        zoom: null,
        scrollZoom: false
      },
      marker: {
        markerId: 1,
        hintContent: null,
        coords: null
      },
      loadedMap: false
    };
  },
  created() {
    this.getMapInfo();
  },
  methods: {
    async getMapInfo() {
      try {
        const response = await API.getMapInfo();

        this.map.settings.apiKey = response.map.key;
        this.map.zoom = response.map.zoom;
        this.map.coords = [response.map.latitude, response.map.longitude];
        this.marker.coords = [response.map.latitude, response.map.longitude];
        this.zoom = response.map.zoom;
        this.marker.hintContent = response.map.hint;

        this.loadedMap = true;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.contacts {
  &__title {
    font-size: 1.625rem;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    opacity: 0.6;
  }

  &__link {
    color: #000000;
    text-decoration: none;
    font-size: 1.625rem;
  }
}

.ymap-container {
  height: 25.75rem;
  border-radius: 0.625rem;
}
</style>

<template>
  <section class="goods mt-6">
    <div class="container">
      <div class="row" v-html="$t('mainGoods.title')"></div>
      <div class="row mt-5">
        <div class="col-sm-4">
          <div class="goods__card goods__card_first">
            <div class="goods__card-content">
              <h5 class="goods__title" v-html="$t('mainGoods.firstBlock.title')"></h5>
              <p class="mt-3 goods__paragraph">
                {{ $t('mainGoods.firstBlock.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mt-5 mt-sm-0">
          <div class="goods__card goods__card_second">
            <div class="goods__card-content">
              <h5 class="goods__title" v-html="$t('mainGoods.secondBlock.title')"></h5>
              <p class="mt-3 goods__paragraph">
                {{ $t('mainGoods.secondBlock.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mt-5 mt-sm-0">
          <div class="goods__card goods__card_third">
            <div class="goods__card-content">
              <h5 class="goods__title" v-html="$t('mainGoods.thirdBlock.title')"></h5>
              <p class="mt-3 goods__paragraph">
                {{ $t('mainGoods.thirdBlock.description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.goods {
  &__card {
    background-color: white;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    box-shadow: -1rem 1rem 32rem rgba(67, 86, 100, 0.165947);
    min-height: 37.688rem;
    z-index: 1;

    &-content {
      z-index: 2;
    }

    &_first,
    &_second,
    &_third {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }

    &_first {
      &::before {
        background-image: url('../../assets/index/goods-first.svg');
      }
    }

    &_second {
      &::before {
        background-image: url('../../assets/index/goods-second.svg');
      }
    }

    &_third {
      &::before {
        background-image: url('../../assets/index/goods-third.svg');
      }
    }
  }

  &__title {
    font-family: 'Fira Sans', sans-serif;
  }

  &__paragraph {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
</style>

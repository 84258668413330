import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './locales/i18n';
import './assets/style/main.scss';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');

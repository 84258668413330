<template>
  <header>
    <Loader v-if="!localLoaded" />

    <nav
      v-else
      :class="[
        'navbar',
        'navbar-expand-lg',
        'bg-transparent',
        { 'navbar-light': black },
        { 'navbar-dark': !black }
      ]"
    >
      <div class="container ">
        <a @click="$router.push('/')" class="navbar-brand">
          <img class="logo" src="../assets/logo.svg" alt="" width="30" height="24" />
        </a>
        <button class="navbar-toggler" type="button" @click="navbarShow = !navbarShow">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          v-show="navbarShow"
          class="collapse navbar-collapse justify-content-around show"
          id="navbar"
        >
          <ul class="navbar-nav m-lg-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                @click="$router.push('/services')"
                :class="['nav-link', { active: active === 'services' }]"
              >
                {{ $t('navbar.services') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/about')"
                :class="['nav-link', { active: active === 'about' }]"
              >
                {{ $t('navbar.about') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/documents')"
                :class="['nav-link', { active: active === 'documents' }]"
              >
                {{ $t('navbar.docs') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/news')"
                :class="['nav-link', { active: active === 'news' }]"
              >
                {{ $t('navbar.news') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contacts">
                {{ $t('navbar.contacts') }}
              </a>
            </li>
          </ul>

          <div class="dropdown">
            <button
              @click="dropdown = !dropdown"
              type="button"
              :class="['btn', 'btn-transparent', 'dropdown-toggle', { black }]"
            >
              {{ currentLang }}
            </button>
            <ul class="dropdown-menu show" v-if="dropdown">
              <li v-if="currentLang !== 'Рус'">
                <a @click="switchLang('ru')" :class="['dropdown-item', { 'item-light': !black }]">
                  Рус
                </a>
              </li>
              <li v-if="currentLang !== 'Каз'">
                <a @click="switchLang('kz')" :class="['dropdown-item', { 'item-light': !black }]">
                  Каз
                </a>
              </li>
              <li v-if="currentLang !== 'Eng'">
                <a @click="switchLang('en')" :class="['dropdown-item', { 'item-light': !black }]">
                  Eng
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { getLangs } from '@/locales/i18n';
import Loader from './ui/Loader.vue';

export default {
  components: {
    Loader
  },
  props: {
    active: {
      type: String,
      default: 'index'
    },
    black: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdown: false,
      lang: localStorage.getItem('lang') || 'ru',
      localLoaded: false,
      navbarShow: false
    };
  },
  computed: {
    currentLang() {
      return this.lang == 'kz' ? 'Каз' : this.lang == 'en' ? 'Eng' : 'Рус';
    }
  },
  created() {
    this.fetchLangs(this.lang);
  },
  methods: {
    switchLang(val) {
      this.dropdown = false;
      localStorage.setItem('lang', val);
      this.lang = val;
      this.$i18n.locale = val;
    },
    async fetchLangs(val) {
      try {
        await getLangs(val);
        this.switchLang(val);
      } catch (e) {
        console.log(e);
      } finally {
        this.localLoaded = true;
        this.$emit('localLoaded', true);
      }
    }
  }
};
</script>

<style scoped lang="scss">
#navbar {
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 5rem;
  }
}

.logo {
  height: 50px;
  width: auto;
  cursor: pointer;
}

.btn {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.nav-link {
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 0.5rem 0 0.5rem 0;
  }
}

.active {
  color: #000000 !important;
  text-decoration: underline;
}

.black {
  color: #000000;

  &:hover {
    color: #000000;
  }
}

.dropdown-menu {
  right: 0;
  background-color: transparent;
  min-width: auto;
  border: none;

  .dropdown-item:not(.item-light) {
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      transition: 0.2s;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  .item-light {
    color: #ffffff;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      transition: 0.2s;
      color: rgba(255, 255, 255, 0.75);
    }
  }
}
</style>

<template>
  <main>
    <Main @localLoaded="localLoaded = $event" />
    <template v-if="localLoaded && !loading">
      <About :image="aboutImage" />
      <HowWeWork />
      <Products />
      <Stock :image="stockImage" />
      <Coop />
      <Order />
      <Goods />
      <Purchase :image="purchaseImage" />
      <Contacts />
      <Footer />
    </template>
  </main>
</template>

<script>
import API from '@/api';

import Main from './Main.vue';
import About from './About.vue';
import HowWeWork from './HowWeWork.vue';
import Products from './Products.vue';
import Stock from './Stock.vue';
import Coop from './Coop.vue';
import Order from './Order.vue';
import Goods from './Goods.vue';
import Purchase from './Purchase.vue';
import Contacts from './Contacts.vue';
import Footer from '@/components/Footer.vue';

import Swiper, { Navigation, Pagination, Autoplay, EffectCube } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Autoplay, EffectCube]);

export default {
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'Home',
  components: {
    Main,
    About,
    HowWeWork,
    Products,
    Stock,
    Coop,
    Order,
    Goods,
    Purchase,
    Contacts,
    Footer
  },
  data: () => ({
    aboutImage: null,
    stockImage: null,
    purchaseImage: null,
    loading: true,
    localLoaded: false
  }),
  created() {
    this.getBlocksImages();
  },
  methods: {
    async getBlocksImages() {
      try {
        const response = await API.getBlocksImages();
        this.aboutImage = response.aboutImage.path;
        this.stockImage = response.stockImage.path;
        this.purchaseImage = response.purchaseImage.path;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>

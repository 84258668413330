import API from './settings';

export default {
  async getLanguages() {
    try {
      const response = await API.get('/admin/api/singletons/get/language/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getBlocksImages() {
    try {
      const response = await API.get('/admin/api/singletons/get/blocksImages/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getMapInfo() {
    try {
      const response = await API.get('/admin/api/singletons/get/map/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getCoopSlider() {
    try {
      const response = await API.get('/admin/api/singletons/get/partners/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getFooterVideo() {
    try {
      const response = await API.get('/admin/api/singletons/get/footerVideo/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getHeaderVideo() {
    try {
      const response = await API.get('/admin/api/singletons/get/headerVideo/');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getNews() {
    try {
      const response = await API.get('/admin/api/rss');
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
  async sendMessage(data) {
    try {
      const response = await API.post('/admin/api/forms/submit/mainForm', data, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }
};

<template>
  <div v-if="show">
    <Modal :title="title" @close="$emit('update:show', false)">
      <template #body>
        {{ description }}
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../components/ui/Modal.vue';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  components: {
    Modal
  }
};
</script>

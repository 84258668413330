<template>
  <div v-if="show">
    <Modal title="Консультация" @close="$emit('update:show', false)">
      <template #body>
        <form class="form" @submit.prevent="sendMessage" v-if="!sended && !error">
          <div class="form-group">
            <label for="name">Имя</label>
            <input
              type="text"
              class="form-control"
              id="name"
              aria-describedby="name"
              placeholder="Введите имя"
              v-model="name"
            />
          </div>
          <div class="form-group">
            <label for="phone">Телефон</label>
            <input
              type="text"
              class="form-control"
              id="phone"
              placeholder="Введите телефон"
              v-model="phone"
            />
          </div>
          <button type="submit" class="btn btn-primary">Отправить</button>
        </form>
        <div v-if="error">
          Ошибка при отправке!
        </div>
        <div v-if="sended">
          Заявка отправлена!
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../components/ui/Modal.vue';
import API from '@/api';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: null,
      phone: null,
      sended: false,
      error: false
    };
  },
  components: {
    Modal
  },
  methods: {
    async sendMessage() {
      try {
        const body = {
          form: {
            name: this.name,
            phone: this.phone
          }
        };
        await API.sendMessage(body);
        this.sended = true;
      } catch (error) {
        this.error = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form {
  .btn {
    margin-top: 2rem;
  }

  &-group {
    margin-top: 1rem;

    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
</style>

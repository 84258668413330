<template>
  <section class="purchase mt-6">
    <div class="container">
      <div class="row" v-html="$t('mainPurchase.title')"></div>
      <div class="row mt-5 gx-4">
        <div class="col-sm-5">
          <img :src="getUrl + image" class="img-fluid" alt="..." />
        </div>
        <div class="col-sm-7 mt-sm-0 mt-5" v-html="$t('mainPurchase.description')"></div>
      </div>
    </div>
  </section>
</template>

<script>
import enviroment from '@/mixins/enviroment';

export default {
  mixins: [enviroment],
  props: {
    image: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.purchase {
  &__paragraph {
    position: relative;
    padding-left: 6.5rem;
    font-size: 1.22rem;

    &::before {
      content: '';
      position: absolute;
      background-image: url('../../assets/index/purchase-paragraph.svg');
      background-repeat: no-repeat;
      left: 0;
      top: 0.5rem;
      height: 100%;
      width: 100%;
    }
  }
}
</style>

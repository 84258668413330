<template>
  <section class="coop mt-6">
    <div class="container">
      <div class="row">
        <h2>
          {{ $t('mainCoop.title') }}
        </h2>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="coop__swiper-wrapper py-5">
            <swiper ref="swiperFirst" :options="swiperConfig">
              <swiper-slide v-for="(slide, index) in firstLine" :key="`firstLineSlide-${index}`">
                <img :src="getUrl + slide.path" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

            <div class="mt-3"></div>

            <swiper ref="swiperSecond" :options="swiperConfig">
              <swiper-slide v-for="(slide, index) in secondLine" :key="`secondLineSlide-${index}`">
                <img :src="getUrl + slide.path" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

            <div class="mt-3"></div>

            <swiper ref="swiperThird" :options="swiperConfig">
              <swiper-slide v-for="(slide, index) in thirdLine" :key="`thirdLineSlide-${index}`">
                <img :src="getUrl + slide.path" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import API from '@/api';
import enviroment from '@/mixins/enviroment';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default {
  mixins: [enviroment],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      loading: false,
      firstLine: null,
      secondLine: null,
      thirdLine: null,

      swiperConfig: {
        observer: true,
        autoplay: {
          delay: 800,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        },
        loop: true,
        spaceBetween: 40,
        centeredSlides: true,
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 7,
            spaceBetween: 40
          }
        }
      }
    };
  },
  computed: {
    swiperFirst() {
      return this.$refs.swiperSecond.$swiper;
    }
  },
  mounted() {
    this.getCoopSlider();
  },
  methods: {
    async getCoopSlider() {
      try {
        this.loading = true;
        const response = await API.getCoopSlider();
        const { firstLine, secondLine, thirdLine } = response.slider;

        this.firstLine = firstLine;
        this.secondLine = secondLine;
        this.thirdLine = thirdLine;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.coop {
  &__swiper-wrapper {
    background-color: white;
    border-radius: 0.625rem;
    box-shadow: 0rem 3.25rem 3.375rem rgba(101, 83, 62, 0.0968);

    .swiper-container {
      width: 100%;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-slide img {
      display: block;
      max-height: 5rem;
      filter: grayscale(100%);

      &:hover {
        transition: 0.3s;
        filter: none;
      }
    }
  }
}
</style>

<template>
  <footer class="footer" v-if="!loading">
    <div class="container-fluid g-0">
      <div class="footer__video">
        <video :src="getUrl + '/' + video" autoplay loop playsinline :muted="isMuted"></video>
        <div class="footer__video-content" v-html="$t('footer.title')"></div>

        <nav class="footer__video-bottom container">
          <div class="row">
            <div class="col-sm-2 d-sm-flex align-items-center">
              <Button :icon="mutedIcon" @click="changeMuted" />

              <img
                class="footer__logo"
                src="../assets/wakeup-logo.svg"
                alt=""
                width="30"
                height="24"
                @click="goToWakeUp"
              />
            </div>
            <div class="col-sm-8 d-sm-flex align-items-center justify-content-center">
              <ul class="footer__video-nav">
                <li class="footer__video-nav-item">
                  <a class="footer__video-nav-link" @click="$router.push('/services')">
                    {{ $t('navbar.services') }}
                  </a>
                </li>
                <li class="footer__video-nav-item">
                  <a class="footer__video-nav-link" @click="$router.push('/about')">
                    {{ $t('navbar.about') }}
                  </a>
                </li>
                <li class="footer__video-nav-item">
                  <a class="footer__video-nav-link" @click="$router.push('/documents')">
                    {{ $t('navbar.docs') }}
                  </a>
                </li>
                <li class="footer__video-nav-item">
                  <a class="footer__video-nav-link" @click="$router.push('/news')">{{
                    $t('navbar.news')
                  }}</a>
                </li>
              </ul>
            </div>
            <div
              class="col-sm-2 d-sm-flex mt-3 mt-sm-0 align-items-center justify-content-end"
              @click="goToTop"
            >
              <span class="footer__to-top">{{ $t('footer.up') }}</span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
import API from '@/api';
import Button from '@/components/ui/Button';
import enviroment from '@/mixins/enviroment';
import globalStore from '@/store/global';

export default {
  mixins: [enviroment],
  components: {
    Button
  },
  data() {
    return {
      loading: false,
      video: null
    };
  },
  mounted() {
    this.getFooterVideo();
  },
  methods: {
    goToWakeUp() {
      window.open('https://xn--80adjqf5a.kz/');
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    async getFooterVideo() {
      try {
        this.loading = true;
        const response = await API.getFooterVideo();
        this.video = response.video;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changeMuted() {
      if (!globalStore.isMutedMain) {
        globalStore.isMutedMain = true;
      }

      globalStore.isMutedFooter = !globalStore.isMutedFooter;
    }
  },
  computed: {
    isMuted() {
      return globalStore.isMutedFooter;
    },
    mutedIcon() {
      return this.isMuted
        ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.455 0l-3.234 3.984-7.221 4.016v2.288l3.836-2.136-5.844 7.198v-7.35h-4.992v10h2.842l-3.842 4.731 1.545 1.269 18.455-22.731-1.545-1.269zm-14.455 16h-1v-6h1v6zm13-8.642v15.642l-8.749-4.865 1.277-1.573 5.472 3.039v-9.779l2-2.464z"/></svg>'
        : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 9v6h-1v-6h1zm13-7l-9 5v2.288l7-3.889v13.202l-7-3.889v2.288l9 5v-20zm-11 5h-5v10h5v-10zm13.008 2.093c.742.743 1.2 1.77 1.198 2.903-.002 1.133-.462 2.158-1.205 2.9l1.219 1.223c1.057-1.053 1.712-2.511 1.715-4.121.002-1.611-.648-3.068-1.702-4.125l-1.225 1.22zm2.142-2.135c1.288 1.292 2.082 3.073 2.079 5.041s-.804 3.75-2.096 5.039l1.25 1.254c1.612-1.608 2.613-3.834 2.616-6.291.005-2.457-.986-4.681-2.595-6.293l-1.254 1.25z"/></svg>';
    }
  }
};
</script>

<style lang="scss">
.footer {
  margin-top: 8.75rem;
  position: relative;

  @mixin coverer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__video {
    position: absolute;
    text-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      background-color: #000000;
      opacity: 0.4;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &,
    video,
    &-content {
      @include coverer;
      width: 100%;
    }

    video {
      background: brown;
      object-fit: cover;
      width: 100%;
    }

    &-content {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-bottom {
      position: absolute;
      bottom: 3.5rem;
      width: 100%;
      z-index: 2;
    }

    &-nav {
      list-style-type: none;
      margin-bottom: 0;
      padding: 0;

      &-item {
        display: inline;
        padding: 1rem;
        cursor: pointer;

        @media (max-width: 768px) {
          display: none;
        }
      }

      &-link {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  &__title {
    color: #ffffff;
  }

  &__to-top {
    display: inline;
    color: #ffffff;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 4rem;
      background-image: url('../assets/index/icons/arrow-top.svg');
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
  }

  &__paragraph {
    color: #ffffff;
    font-size: 1.75rem;
    font-weight: 400;
  }

  &__logo {
    margin-left: 3rem;
    height: 3.928rem;
    width: auto;
    cursor: pointer;
  }
}
</style>

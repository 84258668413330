<template>
  <section class="stock mt-6">
    <div class="container">
      <div class="row" v-html="$t('mainStock.title')"></div>
      <div class="row">
        <div
          class="col-sm-7 mt-5 pe-5 d-flex flex-column justify-content-space-between"
          v-html="$t('mainStock.description')"
        ></div>
        <div class="col-sm-5 mt-5">
          <img :src="getUrl + image" class="img-fluid" alt="..." />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import enviroment from '@/mixins/enviroment';

export default {
  mixins: [enviroment],
  props: {
    image: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.stock {
  &__title {
    font-family: 'Fira Sans', sans-serif;
  }

  &__paragraph {
    font-size: 1.375rem;
  }
}
</style>

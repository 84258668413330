<template>
  <section class="main">
    <div class="video">
      <div class="video__overlay"></div>
      <video
        class="video__content"
        :src="getUrl + '/' + video"
        autoplay
        loop
        playsinline
        :muted="isMuted"
      ></video>
    </div>
    <Navbar @localLoaded="setlocalLoaded" />
    <div v-if="localLoaded" class="header d-flex justify-content-center align-items-center">
      <div class="col-8">
        <h1 class="header__title text-center">
          {{ $t('mainTitle') }}
        </h1>
        <p class="text-center header__paragraph mt-4">
          {{ $t('mainParagraph') }}
        </p>
        <div class="row justify-content-center align-items-center mt-5">
          <transition name="fade">
            <ContactModal :show.sync="contactModalShow" />
          </transition>
          <Button :title="$t('mainButton')" bold @click="contactModalShow = true" />
          <div class="header__contact d-flex flex-column ms-4">
            <a class="header__contact_link" href="tel:+7 701 788 99 33">
              +7 701 788 99 33
            </a>
            <span class="header__contact_text">
              {{ $t('mainContact') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-flex">
      <Button :icon="mutedIcon" @click="changeMuted" />
    </div>
  </section>
</template>

<script>
import Button from '@/components/ui/Button';
import Navbar from '@/components/Navbar';
import ContactModal from '@/components/ContactModal';
import enviroment from '@/mixins/enviroment';
import API from '@/api';
import globalStore from '@/store/global';

export default {
  mixins: [enviroment],
  components: {
    Button,
    Navbar,
    ContactModal
  },
  data() {
    return {
      contactModalShow: false,
      localLoaded: false,
      video: null
    };
  },
  created() {
    this.getHeaderVideo();
  },
  methods: {
    setlocalLoaded(val) {
      this.$emit('localLoaded', val);
      this.localLoaded = val;
    },
    async getHeaderVideo() {
      try {
        this.loading = true;
        const response = await API.getHeaderVideo();
        this.video = response.video;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changeMuted() {
      if (!globalStore.isMutedFooter) {
        globalStore.isMutedFooter = true;
      }

      globalStore.isMutedMain = !globalStore.isMutedMain;
    }
  },
  computed: {
    isMuted() {
      return globalStore.isMutedMain;
    },
    mutedIcon() {
      return this.isMuted
        ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.455 0l-3.234 3.984-7.221 4.016v2.288l3.836-2.136-5.844 7.198v-7.35h-4.992v10h2.842l-3.842 4.731 1.545 1.269 18.455-22.731-1.545-1.269zm-14.455 16h-1v-6h1v6zm13-8.642v15.642l-8.749-4.865 1.277-1.573 5.472 3.039v-9.779l2-2.464z"/></svg>'
        : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 9v6h-1v-6h1zm13-7l-9 5v2.288l7-3.889v13.202l-7-3.889v2.288l9 5v-20zm-11 5h-5v10h5v-10zm13.008 2.093c.742.743 1.2 1.77 1.198 2.903-.002 1.133-.462 2.158-1.205 2.9l1.219 1.223c1.057-1.053 1.712-2.511 1.715-4.121.002-1.611-.648-3.068-1.702-4.125l-1.225 1.22zm2.142-2.135c1.288 1.292 2.082 3.073 2.079 5.041s-.804 3.75-2.096 5.039l1.25 1.254c1.612-1.608 2.613-3.834 2.616-6.291.005-2.457-.986-4.681-2.595-6.293l-1.254 1.25z"/></svg>';
    }
  }
};
</script>

<style scoped lang="scss">
.video {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;

  &__overlay {
    position: absolute;
    background-color: #000;
    opacity: 0.4;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -2;
  }
}

.main {
  min-height: 100vh;
}

.header {
  min-height: 70vh;

  &__title,
  &__paragraph {
    color: #ffffff;
  }

  &__paragraph {
    font-size: 1.375rem;
  }

  &__contact {
    width: auto;

    &_link,
    &_text {
      width: auto;
    }

    &_link {
      font-size: 1.375rem;
      text-decoration: none;
    }

    &_text {
      font-weight: 300;
      font-size: 0.85rem;
      color: #ffffff;
      opacity: 0.5;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

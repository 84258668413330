<template>
  <section class="products mt-6">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <h2 class="products__title">
          {{ $t('mainProducts.title') }}
        </h2>

        <div class="products__navigation d-flex justify-content-end align-items-end">
          <img class="swiper-next me-5" src="../../assets/index/icons/arrow.svg" alt="next" />
          <img class="swiper-prev" src="../../assets/index/icons/arrow.svg" alt="prev" />
        </div>
      </div>
      <ProductsModal
        :show.sync="productsModalShow"
        :title="currentProduct.title"
        :description="currentProduct.description"
      />
      <div class="row mt-5" v-if="$t('mainProducts.slider')">
        <div class="col">
          <div class="products__swiper swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(slide, index) in $t('mainProducts.slider')"
                :key="`slideProducts-${index}`"
                @click="showProductModal(slide)"
              >
                <img :src="getUrl + slide.value.image.path" />
                <h6 class="products__title mt-3">
                  {{ slide.value.title }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Autoplay } from 'swiper';
import ProductsModal from '@/components/ProductsModal';
import 'swiper/swiper-bundle.css';
import enviroment from '@/mixins/enviroment';

Swiper.use([Navigation, Autoplay]);

export default {
  components: {
    ProductsModal
  },
  mixins: [enviroment],
  data() {
    return {
      productsModalShow: false,
      currentProduct: {
        title: null,
        description: null
      }
    };
  },
  mounted() {
    new Swiper('.products__swiper', {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 30,
      grabCursor: true,
      autoplay: {
        delay: 800,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      },
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 30
        }
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      }
    });
  },
  methods: {
    showProductModal(slide) {
      this.productsModalShow = true;
      this.currentProduct.title = slide.value.title;
      this.currentProduct.description = slide.value.description;
    }
  }
};
</script>

<style scoped lang="scss">
.products {
  &__title {
    display: block;
    width: auto;
  }

  &__navigation {
    width: auto;
  }

  &__content {
    height: 500px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    .products__title {
      font-family: 'Fira Sans', sans-serif;
      font-size: 1.375rem;
      font-weight: 400;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 200px;
    border-radius: 0.625rem;
  }

  .swiper-next {
    transform: rotate(90deg);
  }

  .swiper-prev {
    transform: rotate(-90deg);
  }

  .swiper-next,
  .swiper-prev {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    padding: 1.2rem;
    background-color: #f7c723;
    border-radius: 50%;
  }
}
</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import API from '@/api';

Vue.use(VueI18n);

const i18n = new VueI18n({
  lazy: true,
  silentTranslationWarn: true
});

function normalizeLocales(locales) {
  const normalized = {
    en: {},
    ru: {},
    kz: {}
  };

  Object.entries(locales).forEach(([name, value]) => {
    if (typeof value == 'object') {
      Object.entries(value)
        .filter(([val]) => val === 'ru' || val === 'kz' || val === 'en')
        .forEach(([element, key]) => {
          normalized[element][name] = key;
        });
    }
  });

  return normalized;
}

async function getLangs() {
  const response = await API.getLanguages();
  const normalized = normalizeLocales(response);

  Object.entries(normalized).forEach(([name, value]) => {
    i18n.setLocaleMessage(name, value);
  });
}

export default i18n;

export { getLangs };
